import { Directive, Input, TemplateRef, ViewContainerRef, OnDestroy, AfterViewInit } from '@angular/core';
import { Observable, Subject, interval } from 'rxjs';
import { takeUntil, delayWhen } from 'rxjs/operators';
import { IDataStatus } from '../redux-extensions/data-status.model';

@Directive({ selector: '[dataPortalWhenLoading]' })
export class WhenLoadingDirective implements AfterViewInit, OnDestroy {
  private destroyed$ = new Subject<void>();
  private hasView = false;

  constructor(private templateRef: TemplateRef<never>, private viewContainer: ViewContainerRef) { }

  @Input()
  public dataPortalWhenLoading: Observable<IDataStatus>;

  public ngAfterViewInit(): void {
    this.dataPortalWhenLoading.pipe(
      delayWhen(status => status.dataLoading === undefined ? interval(100) : interval(0)),
      takeUntil(this.destroyed$),
    ).subscribe(status => {
      if (status.dataLoading && !this.hasView) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.hasView = true;
      } else if (!status.dataLoading && this.hasView) {
        this.viewContainer.clear();
        this.hasView = false;
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
