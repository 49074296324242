import { AbstractControl, ValidationErrors, AsyncValidatorFn, FormGroup } from '@angular/forms';
import { ServerError, ValidationError } from './server-error.model';
import { map, first, distinctUntilChanged } from 'rxjs/operators';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

export class ServerValidation {

  public static isServerError(error: unknown): boolean {
    const errorId = (<ServerError>error)?.errorId;

    return errorId !== null && errorId !== undefined;
  }

  public static isServerValidationError(error: unknown): boolean {
    return this.isServerError(error) && (<ValidationError>error)?.validationFailures?.length > 0;
  }

  public static tryHandle(errorResponse: HttpErrorResponse, validationError$: BehaviorSubject<ServerError>): boolean {
    const serverError = errorResponse.error;

    if (ServerValidation.isServerError(serverError)) {
      const error = serverError as ServerError;
      validationError$.next(error);
      return true;
    } else {
      return false;
    }
  }

  public static propertyValidator(errors: Observable<ServerError>): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      return this.createValidator(control, errors);
    };
  }

  public static formValidator(errors: Observable<ServerError>, isFormSubmitted$: BehaviorSubject<boolean>): AsyncValidatorFn {
    return (_: FormGroup): Observable<ValidationErrors> => {
      return this.createValidator(_, errors, isFormSubmitted$);
    };
  }

  public static sectionValidationErrorExists(error: ValidationError, sectionName: string): boolean {
    const errors = error && error.validationFailures
      .filter(x => x.propertyName && x.propertyName.startsWith(`${sectionName}.`));

    return errors && errors.length > 0;
  }

  public static getSectionValidationError(error: ValidationError, sectionName: string): ValidationError {
    return error && {
      ...error,
      validationFailures: error.validationFailures
        .filter(x => x.propertyName && x.propertyName.startsWith(`${sectionName}.`))
        .map(x => ({ ...x, propertyName: x.propertyName.substr(sectionName.length + 1) }))
    };
  }

  public static getValidationErrorSections(error: ValidationError): string[] {
    return error && error.validationFailures && error.validationFailures
      .map(x => x.propertyName && x.propertyName.substr(0, x.propertyName.indexOf('.')));
  }

  private static createValidator(control: AbstractControl, errors: Observable<ServerError>,
    isFormSubmitted$?: BehaviorSubject<boolean>): Observable<ValidationErrors> {
    return errors.pipe(
      map(error => {
        if (!error)
          return null;

        if (isFormSubmitted$)
          return this.getFormValidationErrors(error, isFormSubmitted$);

        control.markAsTouched();
        return this.getPropertyValidationErrors(error, control);
      }),
      first());
  }

  private static getPropertyValidationErrors(error: ServerError, control: AbstractControl): ValidationErrors {
    if (error.errorId.includes('VALIDATION_ERROR')) {
      const failures = (error as ValidationError).validationFailures;
      const propertyName = this.getPropertyName(control);

      return failures
        .filter(x => x.propertyName === propertyName &&
          (x.propertyValueValidationDetails.propertyValue === control.value
            || this.bothEmpty(x.propertyValueValidationDetails.propertyValue, control.value?.value)))
        .reduce((acc, e) => ({ ...acc, [e.errorId]: e }), {});
    } else {
      return null;
    }
  }

  private static bothEmpty(x: unknown, y: unknown): boolean {
    return (x === null || x === undefined) && (y === null || y === undefined);
  }

  private static getFormValidationErrors(error: ServerError, isFormSubmitted$: BehaviorSubject<boolean>): ValidationErrors {
    if (!isFormSubmitted$.value)
      return null;

    if (error.errorId.includes('VALIDATION_ERROR')) {
      const failures = (error as ValidationError).validationFailures;

      return failures
        .filter(x => !x.propertyName || x.propertyName === '*')
        .reduce((acc, e) => ({ ...acc, [e.errorId]: e }), {})
    } else {
      return { [error.errorId]: true };
    }
  }

  public static monitorFormControlsValueChange(form: FormGroup, isFormSubmitted$: BehaviorSubject<boolean>): Subscription {
    return form.valueChanges
      .pipe(
        distinctUntilChanged((x, y) => Object.keys(x).every(n => x[n] === y[n])),
      )
      .subscribe(_ => isFormSubmitted$.next(false));
  }

  public static monitorValidationErrors(error$: Observable<ServerError>, form: FormGroup, isFormSubmitted$?: BehaviorSubject<boolean>
  ): Subscription {
    return error$.subscribe(_ => {
      if (isFormSubmitted$)
        isFormSubmitted$.next(true);

      Object.keys(form.controls).forEach(x => {
        form.get(x).updateValueAndValidity();
      });
    });
  }

  private static getPropertyName(control: AbstractControl): string {
    if (control.parent) {
      const propertyName = Object.keys(control.parent.controls)
        .find(name => control === control.parent.get(name));
      return propertyName;
    } else {
      return undefined;
    }
  }
}
