import { Component, Input, Output, TemplateRef, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { EMPTY, Observable, of, ReplaySubject, Subject, timer } from 'rxjs';
import { IDataStatus } from '@data-portal/common-ui';
import { IListLabels, IListComponent, IPage, IPageChange, IRowMenuActions, ListFilterProperties } from './list.model';
import { Column } from './list-table/list-table.model';
import { actionButtonRotate, actionButtonsDisplay } from './animations';
import { ListFilterComponent } from './list-filter/list-filter.component';
import { ListTableComponent } from './list-table/list-table.component';
import { IRefreshState } from './list-refresh/list-refresh.component';
import { map, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { IOptions } from './list-csv-export';

@Component({
  selector: 'data-portal-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  animations: [actionButtonRotate, actionButtonsDisplay]
})
export class ListComponent<TModel> implements IListComponent<TModel>, AfterViewInit, OnDestroy {

  @Input()
  public labels: IListLabels;
  @Input()
  public icon: string;

  @Input()
  public dataSource$: Observable<IPage<TModel>>;
  @Input()
  public columns: Array<Column<TModel>>;
  @Input()
  public columnTemplates: { [name: string]: TemplateRef<never> }
  @Input()
  public descriptionHeaderTemplates: { [name: string]: TemplateRef<never> }

  @Input()
  public dataStatus$: Observable<IDataStatus>;

  @Input()
  public disableAdd = false;
  @Input()
  public disablePaging = false;

  @Input()
  public rowMenuActions: IRowMenuActions<TModel> = {
    showDetailsAction$: of(true),
    actions: [],
  };

  @Input()
  public filterTemplate: TemplateRef<ListFilterComponent<TModel>>;
  @Input()
  public filterProperties: ListFilterProperties<TModel>;

  @Input()
  public autoRefreshEnabled = false;

  @Input()
  public exportCsvEnabled = false;
  @Input()
  public exportCsvOptions: Partial<IOptions> = undefined;
  @Input()
  public exportCsvElementMapper: (element: TModel) => unknown;

  @Output()
  public changePage = new ReplaySubject<IPageChange<TModel>>();

  @ViewChild(ListTableComponent, { static: false })
  private tableComponent: ListTableComponent<TModel>;

  private refreshState$ = new ReplaySubject<IRefreshState>();
  private destroyed$ = new Subject<void>();

  public ngAfterViewInit(): void {
    this.refreshState$
      .pipe(
        takeUntil(this.destroyed$),
        switchMap(x => {
          return x.enabled ? timer(0, x.interval * 1000).pipe(
            withLatestFrom(this.tableComponent.changePage),
            map(([_, page]) => page),
          ) : EMPTY;
        }),
      )
      .subscribe(x => {
        this.changePage.next(x);
      });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
  }

  public resetFilter(): void {
    this.tableComponent.filterComponent.clear();
  }

  public intervalChanged(refreshState: IRefreshState) {
    this.refreshState$.next(refreshState);
  }
}
