import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UnauthorizedHttpInterceptorService implements HttpInterceptor {
  constructor(private router: Router) { }

  public intercept(request: HttpRequest<never>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          this.router.navigateByUrl(`/login?returnUrl=${this.router.url}`)
          return of(null);
        }

        return throwError(error);
      })
    );
  }
}

export const UnauthorizedHttpInterceptorServiceProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: UnauthorizedHttpInterceptorService,
  multi: true
};
