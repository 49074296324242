<mat-card-content>
  <table mat-table [dataSource]="data">
    <ng-container matColumnDef="filter">
      <th mat-header-cell *matHeaderCellDef [attr.colspan]="displayedColumns.length">
        <data-portal-list-filter #tableFilter (filterChanged)="changeFilter($event)"
          [filterProperties]="filterProperties">
          <ng-template [ngTemplateOutlet]="filterTemplate" [ngTemplateOutletContext]="{form:tableFilter.filterForm}">
          </ng-template>
        </data-portal-list-filter>
      </th>
    </ng-container>

    <ng-container *ngIf="descriptionHeaderTemplates">
      <ng-container *ngFor="let columnName of displayedColumns" matColumnDef="{{columnName + 'HeaderTemplate'}}">
        <th mat-header-cell *matHeaderCellDef>
          <ng-template [ngTemplateOutlet]="getHeaderDescriptionTemplate(columnName)"></ng-template>
        </th>
      </ng-container>
    </ng-container>

    <ng-container *ngFor="let column of columns" matColumnDef="{{column.name}}">
      <th mat-header-cell *matHeaderCellDef [ngClass]="getColumnClass(column.type)">
        {{column.header | translate}}
      </th>

      <div [ngSwitch]="column.type">

        <div *ngSwitchCase="'Date'">
          <td mat-cell class="column--big" *matCellDef="let element"> {{element[column.name] | ewsDate}} </td>
        </div>

        <div *ngSwitchCase="'Avatar'">
          <td mat-cell *matCellDef="let element">
            <div class="avatar">
              <mat-icon class="avatar__disabled" *ngIf="isDisabled(column, element)">block</mat-icon>
              <img [src]="element[column.name]">
            </div>
          </td>
        </div>

        <!-- <div *ngSwitchCase="'File'">
          <td mat-cell class="column--big" *matCellDef="let element" class="right-aligned">
            <a href="{{ fileStoreUrl + '/' + element[column.name] }}" download>
              <mat-icon>cloud_download</mat-icon>
            </a>
          </td>
        </div> -->

        <div *ngSwitchCase="'Icon'">
          <td mat-cell *matCellDef="let element">
            <div class="icon">
              <mat-icon>{{element[column.name]}}</mat-icon>
            </div>
          </td>
        </div>

        <div *ngSwitchCase="'Number'">
          <td mat-cell class="column--big" *matCellDef="let element"> {{element[column.name]}} </td>
        </div>

        <div *ngSwitchCase="'Boolean'">
          <td mat-cell class="column--small" *matCellDef="let element" class="center-aligned">
            <mat-checkbox *ngIf="element[column.name]" [checked]="element[column.name]" disabled></mat-checkbox>
          </td>
        </div>

        <div *ngSwitchCase="'String'">
          <td mat-cell class="column--big" *matCellDef="let element">
            {{element[column.name] ? (element[column.name] | translate) : ''}}
          </td>
        </div>

        <div *ngSwitchCase="'Template'">
          <td mat-cell class="column--small" *matCellDef="let element">
            <ng-template [ngTemplateOutlet]="getColumnTemplate(column)"
              [ngTemplateOutletContext]="{$implicit: element[column.name], element: element, column: column}"></ng-template>
          </td>
        </div>

        <div *ngSwitchDefault>
          <td mat-cell *matCellDef="let element"> {{element[column.name] ? element[column.name] : ''}}
          </td>
        </div>

      </div>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="right-aligned"> </th>
      <td mat-cell *matCellDef="let row" class="right-aligned">
        <button *ngIf="(rowMenuActions.showDetailsAction$ |async) && rowMenuActions.actions.length === 0"
          mat-flat-button
          [routerLink]="!rowMenuActions.showDetailsRoutePrefix ? row.id : (rowMenuActions.showDetailsRoutePrefix + '/' + row.id)">
          Go to details <mat-icon>arrow_forward_ios</mat-icon>
        </button>

        <button mat-icon-button [matMenuTriggerFor]="rowMenu" *ngIf="rowMenuActions.actions.length > 0">
          <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #rowMenu="matMenu">
          <a *ngIf="(rowMenuActions.showDetailsAction$ | async)" mat-menu-item [routerLink]="[row.id]">
            <mat-icon>remove_red_eye</mat-icon>{{ labels.detailsAction | translate }}
          </a>
          <ng-container *ngFor="let action of rowMenuActions?.actions">
            <a *ngIf="!action.show || (action.show(row) | async)" mat-menu-item (click)="action.execute(row)">
              <mat-icon>{{action.icon}}</mat-icon>{{ action.translateKey | translate }}
            </a>
          </ng-container>
        </mat-menu>
      </td>
    </ng-container>

    <tr [hidden]="!canFilter" mat-row *matHeaderRowDef="['filter']"></tr>
    <ng-container *ngIf="descriptionHeaderTemplates">
      <tr mat-header-row *matHeaderRowDef="headerDescriptionColumns"></tr>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <data-portal-error-details *dataPortalWhenError="dataStatus$; let details = error" [errorDetails]="details">
  </data-portal-error-details>
  <data-portal-busy-indicator *dataPortalWhenLoading="dataStatus$"></data-portal-busy-indicator>
  <data-portal-empty-state *dataPortalWhenEmpty="dataStatus$" [title]="labels.emptyStateTitle | translate"
    [message]="labels.emptyStateMessage | translate" [ctaLabel]="labels.emptyStateCtaLabel | translate" ctaLink="new"
    [readonly]="readonly || disableAdd">
  </data-portal-empty-state>
</mat-card-content>
<mat-card-actions *ngIf="!disablePaging">
  <mat-paginator [length]="totalEntries" [pageSizeOptions]="pageSizes"></mat-paginator>
</mat-card-actions>