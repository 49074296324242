import { Component, Input } from '@angular/core';

@Component({
  selector: 'data-portal-busy-indicator',
  templateUrl: './busy-indicator.component.html',
  styleUrls: ['./busy-indicator.component.scss']
})
export class BusyIndicatorComponent {
  @Input()
  public loadingTextTranslationKey = 'COMMON_UI.BUSY_INDICATOR.LOADING';
  @Input()
  public hideText = false;
}
