import { Component, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'data-portal-list-filter',
  templateUrl: './list-filter.component.html',
  styleUrls: ['./list-filter.component.scss']
})
export class ListFilterComponent<TModel> {

  @Input()
  public set filterProperties(properties: { [key in keyof TModel]?: unknown }) {
    if (properties) {
      this._properties = properties;
      Object.keys(properties).forEach(x => {
        this.filterForm.addControl(x, new FormControl(properties[x]));
      });
    }
  }
  private _properties: { [key in keyof TModel]?: unknown };

  @Output()
  public filterChanged = new BehaviorSubject<TModel>(undefined);

  @Output()
  public hasFilter = false;

  public filterForm = new FormGroup({});

  public filter(): void {
    const filters = this.filterForm.getRawValue();
    const anyPropertyChanged = Object.keys(filters).some(x => !!filters[x] || typeof filters[x] === 'boolean');

    if (anyPropertyChanged) {
      this.filterChanged.next(filters);
      this.hasFilter = true;
    } else {
      this.clear();
    }
  }

  public clear(): void {
    this.filterForm.reset();
    Object.keys(this._properties).forEach(x => this.filterForm.get(x).setValue(this._properties[x]));
    this.filterChanged.next(undefined);
    this.hasFilter = false;
  }

}
