import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class SessionInfo {
  constructor(public readonly claims: Array<{ type: string, value: string }> ) { }
}

export class LoginForm {
  constructor(public readonly userName: string, public readonly password: string, public readonly rememberMe: boolean) { }
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationApiClientService {

  constructor(private http: HttpClient) { }

  public getSessionInfo(): Observable<SessionInfo> {
    return this.http.get<Array<{ type: string, value: string }>>('/api/v1.0/authentication/session-info').pipe(
      map(response => new SessionInfo(response)),
    );
  }

  public login(form: LoginForm): Observable<unknown> {
    return this.http.post('/api/v1.0/authentication/login', form);
  }

  public logout(): Observable<unknown> {
    return this.http.post('/api/v1.0/authentication/logout', undefined);
  }
}
