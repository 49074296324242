import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogSettings } from '@data-portal/common-ui';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { concatMap, filter, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ConfirmDialogService } from '../confirm-dialog/confirm-dialog.service';
import { DirtyStateComponent } from '../dirty-check';
import { IDataStatus } from '../redux-extensions/data-status.model';

@Component({ template: '' })
export abstract class DetailsBaseComponent<TModel, TId> implements OnInit, OnDestroy, DirtyStateComponent {

  public detailsId: TId;
  public details: TModel;

  public abstract savedMessage: string;
  public abstract deletedMessage: string;
  public abstract dataForm: FormGroup;
  public abstract getDetails: (id: TId) => Observable<TModel>;
  public abstract createDetails: (details: TModel) => Observable<TId>;
  public abstract updateDetails: (id: TId, details: TModel) => Observable<void>;
  public abstract deleteDialogSettings?: ConfirmDialogSettings;
  public abstract deleteDetails: (id: TId) => Observable<void>;

  public dataStatus$ = new ReplaySubject<IDataStatus>();
  public isDirty$ = new BehaviorSubject<boolean>(false);

  protected readonly saved = new Subject<void>();
  protected readonly deleted = new Subject<void>();

  protected readonly destroyed$ = new Subject<void>();

  constructor(protected route: ActivatedRoute, protected router: Router, private snackBar: MatSnackBar,
    private confirm: ConfirmDialogService) {
  }

  public ngOnInit(): void {
    this.route.params
      .pipe(
        first(),
        tap(params => params.id !== 'new'
          ? this.loading()
          : this.loaded()),
        filter(params => params.id !== 'new'),
        map(params => params.id),
        concatMap(id => {
          this.detailsId = id;

          return this.getDetails(this.detailsId);
        }),
      ).subscribe(data => {
        this.details = { ...data, id: this.detailsId };
        this.dataForm.patchValue(this.details);
        this.loaded();
      }, e => this.error(e));

      this.dataForm.valueChanges.pipe(
        takeUntil(this.destroyed$),
        map(_ => this.dataForm.dirty),
      ).subscribe(x => this.isDirty$.next(x));
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
  }

  public save() {
    this.loading();

    const details = this.dataForm.getRawValue();
    const request = this.detailsId
      ? this.updateDetails(this.detailsId, details).pipe(map(() => this.detailsId))
      : this.createDetails(details);

    request
      .pipe(takeUntil(this.destroyed$))
      .subscribe(id => {
        this.detailsId = id;
        this.router.navigate([`../${id}`], { relativeTo: this.route, replaceUrl: true });

        this.saved.next();
        this.loaded(this.savedMessage);

        this.isDirty$.next(false);
      }, e => this.error(e));
  }

  public delete() {
    this.confirm.showDialog(this.deleteDialogSettings)
      .pipe(
        takeUntil(this.destroyed$),
        filter(confirmed => confirmed),
        tap(() => this.loading()),
        switchMap(() => this.deleteDetails(this.detailsId)),
      ).subscribe(() => {
        this.router.navigate(['..'], { relativeTo: this.route, replaceUrl: true });

        this.deleted.next();
        this.loaded(this.deletedMessage);
      }, e => this.error(e));
  }

  private loading = () => this.dataStatus$.next({ ...IDataStatus.loadingStatus });
  private loaded = (message?: string) => {
    this.dataStatus$.next({ ...IDataStatus.loadedNonEmptyStatus });

    if (message) {
      this.snackBar.open(message, 'Close', { duration: 5000 });
    }
  };
  private error = (error: unknown) => this.dataStatus$.next({ ...IDataStatus.errorStatus, error });
}
