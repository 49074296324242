import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, Observable, BehaviorSubject, of, combineLatest } from 'rxjs';
import { takeUntil, filter, map, tap } from 'rxjs/operators';
import { IDataStatus } from '../redux-extensions/data-status.model';
import { IPage, IPageChange, ListFilterProperties } from './list.model';

@Component({ template: '' })
export abstract class FrontEndFilteredListBaseComponent<T> implements OnInit, OnDestroy {

  protected abstract list$: Observable<Array<T>>;
  protected abstract compare: (value: T, filter: ListFilterProperties<T>) => boolean

  public dataSource$ = new BehaviorSubject<IPage<T>>({ elements: [], totalEntries: 0 });
  public dataStatus$ = new BehaviorSubject<IDataStatus>({ ...IDataStatus.loadingStatus });

  protected destroyed$ = new Subject<void>();

  private elements$: Observable<Array<T>>;
  private defaultPage: IPageChange<T> = { pageNumber: 1, pageSize: 10 };
  private page = new BehaviorSubject<IPageChange<T>>(this.defaultPage);

  public ngOnInit(): void {
    this.list$.pipe(takeUntil(this.destroyed$))
      .subscribe(x => {
        this.elements$ = of(x);

        combineLatest([
          this.page,
          this.elements$,
        ]).pipe(
          takeUntil(this.destroyed$),
          filter(([_, list]) => !!list),
          tap(_ => this.dataStatus$.next({ ...IDataStatus.loadingStatus })),
          map(([page, list]) => {
            const filtered = list.filter(value => this.filterComparator(value, page.filter));
            const totalEntries = filtered.length;

            const elements = filtered.slice((page.pageNumber - 1) * page.pageSize, (page.pageNumber) * page.pageSize);

            return { elements, totalEntries };
          }),
        ).subscribe(x => {
          this.dataSource$.next(x);
          this.dataStatus$.next({ ...(x.totalEntries > 0 ? IDataStatus.loadedNonEmptyStatus : IDataStatus.loadedEmptyStatus) });
        }, error => {
          this.dataStatus$.next({ ...IDataStatus.errorStatus, error });
        });
      });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
  }

  public getPage(pageNumber: number, pageSize: number, filter?: T): void {
    this.page.next({ pageNumber, pageSize, filter });
  }

  public resetFilter(): void {
    this.page.next(this.defaultPage);
  }

  private filterComparator(value: T, filter: ListFilterProperties<T>) {
    if (!filter)
      return true;

    return this.compare(value, filter);
  }

  protected compareStrings(elementValue: string, filterValue: string): boolean {
    if (!filterValue)
      return true;

    return this.normalizeText(elementValue).includes(this.normalizeText(filterValue));
  }

  protected compareBooleans(elementValue: boolean, filterValue: boolean): boolean {
    if (filterValue === undefined || filterValue === null)
      return true;

    return elementValue === filterValue;
  }

  protected compareAny(elementValue: unknown, filterValue: unknown): boolean {
    if (filterValue === undefined || filterValue === null)
      return true;

    return elementValue === filterValue;
  }

  protected compareDates(elementValue: Date | string, filterValue: Date): boolean {
    if (!filterValue)
      return true;

    elementValue = new Date(elementValue);
    elementValue.setHours(0, 0, 0, 0);

    return elementValue.getTime() === filterValue.getTime();
  }

  private normalizeText(text: string) {
    return text.toLowerCase().replace(/ /g, '');
  }

}
