import {
  Component,
  Input,
  ViewChild,
  Output,
  AfterViewInit,
  OnDestroy,
  OnChanges,
  // Inject,
  TemplateRef,
} from '@angular/core';
import { IPage, IListTableComponent, IPageChange, IListTableLabels, IRowMenuActions, ListFilterProperties } from '../list.model';
import { Column, ColumnType, TemplateColumn } from './list-table.model';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { ComponentChanges, IDataStatus } from '@data-portal/common-ui';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { takeUntil, debounceTime } from 'rxjs/operators';
// import { CONFIDENTIAL_FILE_UPLOAD_URL, FILE_UPLOAD_URL } from '../../file-upload-list';
import { ListFilterComponent } from '../list-filter/list-filter.component';
import { CsvExporter, IOptions } from '../list-csv-export';

@Component({
  selector: 'data-portal-list-table',
  templateUrl: './list-table.component.html',
  styleUrls: ['../list.component.scss']
})
export class ListTableComponent<TModel> implements AfterViewInit, OnChanges, OnDestroy, IListTableComponent<TModel> {

  @Input()
  public labels: IListTableLabels;

  @Input()
  public dataSource$: Observable<IPage<TModel>>;
  @Input()
  public columns: Array<Column<TModel>>;
  public displayedColumns = [];

  public get headerDescriptionColumns(): Array<string> {
    return this.displayedColumns.filter(x => x).map(x => x + 'HeaderTemplate');
  }

  @Input()
  public columnTemplates: { [name: string]: TemplateRef<never> }
  @Input()
  public descriptionHeaderTemplates: { [name: string]: TemplateRef<never> }

  @Input()
  public dataStatus$: Observable<IDataStatus>;

  @Input()
  public readonly = false;
  @Input()
  public disableAdd = false;
  @Input()
  public disablePaging = false;
  @Input()
  public confidential = false;

  @Input()
  public rowMenuActions: IRowMenuActions<TModel>;

  @Output()
  public changePage = new ReplaySubject<IPageChange<TModel>>();

  public data: Array<TModel> = [];
  public totalEntries = 0;
  public pageSizes: Array<number> = [10, 20, 50]

  @Input()
  public filterTemplate: TemplateRef<ListFilterComponent<TModel>>;
  @Input()
  public filterProperties: ListFilterProperties<TModel>;
  public get canFilter() { return !!this.filterTemplate }
  public showFilter = false;
  @ViewChild(ListFilterComponent, { static: false })
  public filterComponent: ListFilterComponent<TModel>;

  private destroyed$ = new Subject<void>();
  private showDetailsAction = true;

  @ViewChild(MatPaginator, { static: false })
  private paginator: MatPaginator;

  // constructor(@Inject(FILE_UPLOAD_URL) private fileUploadUrl: string,
  //   @Inject(CONFIDENTIAL_FILE_UPLOAD_URL) private confidentialFileUploadUrl: string) { }

  // public get fileStoreUrl(): string {
  //   return this.confidential ? this.confidentialFileUploadUrl : this.fileUploadUrl;
  // }

  public ngAfterViewInit(): void {
    if (this.paginator) {
      this.paginator.page
        .pipe(
          takeUntil(this.destroyed$),
        ).subscribe((page: PageEvent) => {
          this.changePage.next({
            pageNumber: page.pageIndex + 1,
            pageSize: page.pageSize,
            filter: this.filterComponent.filterChanged.value,
          });
        });
    }


    this.dataSource$
      .pipe(
        takeUntil(this.destroyed$),
        debounceTime(100),
      ).subscribe(source => {
        this.data = source.elements;
        this.totalEntries = source.totalEntries;
      });
  }

  public ngOnChanges(changes: ComponentChanges<ListTableComponent<TModel>>) {
    if (changes.columns || changes.readonly) {
      this.updateDisplayedColumns();
    }
    if (changes.rowMenuActions) {
      if (this.rowMenuActions) {
        this.rowMenuActions.showDetailsAction$.pipe(takeUntil(this.destroyed$))
          .subscribe(x => {
            this.showDetailsAction = x;
            this.updateDisplayedColumns();
          });
      }
    }
  }

  public exportAsCsv(exportElementMapper: (element: TModel) => unknown, options: Partial<IOptions>): void {
    const csv = new CsvExporter(this.data.map(exportElementMapper || (x => x)), options);
    csv.generateCsv();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
  }

  public changeFilter(filterValue: TModel): void {
    this.changePage.next({ pageNumber: 1, pageSize: 10, filter: filterValue });
    this.showFilter = false;
  }

  private updateDisplayedColumns() {
    if (this.columns) {
      this.displayedColumns = this.columns.map(c => c.name);
      const showMenu = !this.rowMenuActions || this.showDetailsAction || this.rowMenuActions.actions.length > 0;
      if (!this.readonly && showMenu)
        this.displayedColumns = this.displayedColumns.concat('actions');
    }
  }

  public isDisabled(column: { isDisabled?: (element: TModel) => boolean }, element: TModel): boolean {
    return column.isDisabled && column.isDisabled(element);
  }

  public getColumnClass(columnType: ColumnType): Record<string, boolean> {
    return {
      'center-aligned': columnType == 'Boolean',
      'column--small': [ColumnType.Boolean, ColumnType.File, ColumnType.Template].some(x => x === columnType),
      'column--big': [ColumnType.Date, ColumnType.Number, ColumnType.String].some(x => x === columnType),
    }
  }

  public getHeaderDescriptionTemplate(columnName: string): TemplateRef<never> {
    return this.descriptionHeaderTemplates[columnName];
  }

  public getColumnTemplate(template: TemplateColumn<TModel>): TemplateRef<never> {
    return this.columnTemplates[template.templateName];
  }

}
