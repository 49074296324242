import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgJsonEditorModule } from 'ang-jsoneditor'
import { JsonEditorComponent } from './json-editor.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    JsonEditorComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgJsonEditorModule,
  ],
  entryComponents: [
    JsonEditorComponent,
  ],
  exports: [
    JsonEditorComponent,
  ],
  providers: [
  ]
})
export class JsonEditorModule { }
