import { Directive, Input, TemplateRef, ViewContainerRef, OnDestroy, AfterViewInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IDataStatus } from '../redux-extensions/data-status.model';



@Directive({ selector: '[dataPortalWhenError]' })
export class WhenErrorDirective implements AfterViewInit, OnDestroy {
  private destroyed$ = new Subject<void>();
  private hasView = false;
  private context = {};

  constructor(private templateRef: TemplateRef<never>, private viewContainer: ViewContainerRef) { }

  @Input()
  public dataPortalWhenError: Observable<IDataStatus>;

  public ngAfterViewInit(): void {
    this.dataPortalWhenError.pipe(takeUntil(this.destroyed$)).subscribe(status => {
      if (status.error && !this.hasView) {
        this.context['error'] = status.error;
        this.viewContainer.createEmbeddedView(this.templateRef, this.context);
        this.hasView = true;
      } else if (status.error && this.hasView) {
        this.context['error'] = status.error;
      } else if (!status.error && this.hasView) {
        this.viewContainer.clear();
        this.hasView = false;
        delete this.context['error'];
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
