import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'ewsDate'
})
export class EwsDatePipe extends DatePipe implements PipeTransform {

  public transform(value: string | number | Date, format?: string, timezone?: string, locale?: string): any {
    if (format === 'date') {
      format = 'dd.MM.yyyy';
    }
    if (format === 'shortTime') {
      format = 'HH:mm'
    }
    return super.transform(value, format || 'dd.MM.yyyy HH:mm', timezone, locale);
  }

}
