import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { CommonUiModule, LayoutComponent, LoginComponent, UnauthorizedComponent } from '@data-portal/common-ui';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { AdministratorGuard, IdentityModule } from '@data-portal/identity';

import { AppComponent } from './app.component';

const ROUTES: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'administration',
        canActivate: [AdministratorGuard],
        loadChildren: () => import('@data-portal/administration').then(m => m.AdministrationModule),
      },
      {
        path: 'dashboards',
        loadChildren: () => import('@data-portal/dashboards').then(m => m.DashboardsModule),
      }
    ]
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
  }
];

export function HttpLoaderFactory(http: HttpClient) {
  return new MultiTranslateHttpLoader(http, [
    { prefix: './assets/localization/data-portal.', suffix: '.json' },
    { prefix: './assets/localization/shared.', suffix: '.json' },
  ]);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IdentityModule.forRoot(),
    CommonUiModule,
    RouterModule.forRoot(ROUTES),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(translateService: TranslateService) {
    translateService.setDefaultLang('en');
  }
}
