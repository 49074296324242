import { Observable } from 'rxjs';
import { IDataStatus } from '../redux-extensions/data-status.model';
import { Column } from './list-table/list-table.model';

export interface IListTableLabels {
  emptyStateTitle: string;
  emptyStateMessage: string;
  emptyStateCtaLabel?: string;
  detailsAction?: string;
  remove?: string;
}

export interface IListLabels extends IListTableLabels {
  title: string;
  subTitle: string;
  add?: string;
}

export interface IPage<TModel> {
  elements: Array<TModel>;
  totalEntries: number;
}

export interface IPageChange<TModel> {
  pageNumber: number;
  pageSize: number;
  filter?: TModel;
}

export interface ISecondaryAction {
  label: string;
  path: string;
  icon?: string;
  show$: Observable<boolean>;
}

export interface IListTableComponent<TModel> {
  labels: IListTableLabels;
  dataSource$: Observable<IPage<TModel>>;
  columns: Array<Column<TModel>>;
  dataStatus$: Observable<IDataStatus>;
}

export interface IListComponent<TModel> extends IListTableComponent<TModel> {
  labels: IListLabels;
}

export interface IRowMenuAction<TModel> {
  translateKey: string;
  icon: string;
  execute(data: TModel): void;
  show?(data: TModel): Observable<boolean>;
}

export interface IRowMenuActions<TModel> {
  showDetailsAction$: Observable<boolean>;
  showDetailsRoutePrefix?: string;
  actions: Array<IRowMenuAction<TModel>>
}

function rowMenuAction<TData>(...action: RowMenuAction<TData>): IRowMenuAction<TData>;
function rowMenuAction<TData>(translateKey: string, icon: string,
  execute: RowMenuActionExecute<TData>, show?: RowMenuActionShow<TData>
): IRowMenuAction<TData> {
  return { translateKey, icon, execute, show }
}

type RowMenuActionExecute<TData> = (data: TData) => void;
type RowMenuActionShow<TData> = (data: TData) => Observable<boolean>;
type RowMenuAction<TData> = [
  translateKey: string,
  icon: string,
  execute: RowMenuActionExecute<TData>,
  show?: RowMenuActionShow<TData>,
]

export function rowMenuActionFactory<TData>(actions: Array<RowMenuAction<TData>>): Array<IRowMenuAction<TData>> {
  return actions.map(x => rowMenuAction(...x));
}

export type ListFilterProperties<TModel> = { [key in keyof TModel]?: unknown }
