import { NgModule } from '@angular/core';
import { DirtyCheckGuard } from './dirty-check.guard';

@NgModule({
  declarations: [
  ],
  imports: [
  ],
  providers: [
    DirtyCheckGuard
  ]
})
export class DirtyCheckModule { }
