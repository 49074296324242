import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'data-portal-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent {

  constructor(private router: Router) {
  }

  public goHome(): void {
    this.router.navigateByUrl('/');
  }

}
