import { APP_INITIALIZER, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { AuthenticationApiClientService, LoginForm } from './authentication-api-client.service';
import { CurrentUserService } from './current-user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private api: AuthenticationApiClientService, private currentUser: CurrentUserService, private router: Router) { }

  public checkSession(): Observable<unknown> {
    return this.api.getSessionInfo().pipe(
      tap(sessionInfo => {
        this.currentUser.parseUserClaimsIdentity(sessionInfo);
      }, () => {
        this.router.navigateByUrl(`/login?returnUrl=${window.location.pathname}`);
      }),
      catchError(() => of(null)),
    );
  }

  public login(form: LoginForm): Observable<unknown> {
    return this.api.login(form).pipe(
      switchMap(_ => this.checkSession()),
    );
  }

  public logout(): Observable<unknown> {
    return this.api.logout();
  }

}

function createSessionCheck(auth: AuthenticationService) {
  return () => Promise.all([auth.checkSession().toPromise(), new Promise(resolve => setTimeout(resolve, 3000))]);
}

export const sessionInitializer = {
  provide: APP_INITIALIZER,
  useFactory: createSessionCheck,
  deps: [ AuthenticationService ],
  multi: true,
}
