import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ConfirmDialogComponent, ConfirmDialogSettings, defaultSettings } from './confirm-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ConfirmDialogService {

  constructor(private dialog: MatDialog) { }

  public showDialog(settings?: Partial<ConfirmDialogSettings>): Observable<boolean> {
    const config = {
      width: 'unset',
      height: 'unset',
      minWidth: '400px',
      minHeight: '200px',
      maxWidth: '700px',
      data: {...defaultSettings, ...settings}
    };
    return this.dialog.open(ConfirmDialogComponent, config).afterClosed();
  }
}
