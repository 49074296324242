<mat-card>
  <mat-card-header>
    <mat-card-title>{{ labels.title | translate }}</mat-card-title>
    <mat-card-subtitle>{{ labels.subTitle | translate }}</mat-card-subtitle>
    <div style="flex: 1 1 auto"></div>
    <button *ngIf="exportCsvEnabled" mat-button color="primary" (click)="dataTable.exportAsCsv(exportCsvElementMapper, exportCsvOptions)">Download CSV</button>
    <data-portal-list-refresh *ngIf="autoRefreshEnabled" (intervalChanged)="intervalChanged($event)"></data-portal-list-refresh>
    <button *ngIf="!disableAdd" class="card-action" mat-flat-button color="accent" routerLink="new">{{ labels.add | translate }}</button>
  </mat-card-header>
  <data-portal-list-table #dataTable [labels]="labels" [dataSource$]="dataSource$" [columns]="columns"
    [columnTemplates]="columnTemplates" [dataStatus$]="dataStatus$" (changePage)="changePage.next($event)"
    [disableAdd]="disableAdd" [rowMenuActions]="rowMenuActions" [disablePaging]="disablePaging"
    [filterTemplate]="filterTemplate" [filterProperties]="filterProperties">
  </data-portal-list-table>
</mat-card>