import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@data-portal/material';
import { RouterModule } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { MenuComponent } from './layout/menu/menu.component';
import { UserComponent } from './layout/user/user.component';
import { LoginComponent } from './login/login.component';
import { JsonEditorModule } from './json-editor/json-editor.module';
import { ReactiveFormsModule } from '@angular/forms';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { WhenEmptyDirective, WhenErrorDirective, WhenLoadedDirective, WhenLoadingDirective } from './when-directives';
import { BusyIndicatorComponent } from './busy-indicator/busy-indicator.component';
import { EmptyStateComponent } from './empty-state/empty-state.component';
import { ErrorDetailsComponent } from './error-details/error-details.component';
import { TranslateModule } from '@ngx-translate/core';
import { ListComponent, ListTableComponent, ListFilterComponent, ListRefreshComponent } from './list';
import { HttpClientModule } from '@angular/common/http';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ValidationModule } from '@data-portal/validation';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BooleanSelectComponent } from './boolean-select/boolean-select.component';
import { IconSelectComponent } from './icon-select/icon-select.component';
import { ConfirmDialogService } from './confirm-dialog/confirm-dialog.service';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { DirtyCheckModule } from './dirty-check';
import { EwsDatePipe } from './ews-date/ews-date.pipe';
import { TimeagoModule } from 'ngx-timeago';
import { StatusChipComponent } from './status-chip/status-chip.component';
import { WhenNotEmptyDirective } from './when-directives/when-not-empty.directive';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule.forChild([]),
    MaterialModule,
    HttpClientModule,
    TranslateModule,
    JsonEditorModule,
    ValidationModule,
    MatSnackBarModule,
    DirtyCheckModule,
    TimeagoModule.forChild(),
  ],
  declarations: [
    WhenLoadingDirective,
    WhenLoadedDirective,
    WhenEmptyDirective,
    WhenNotEmptyDirective,
    WhenErrorDirective,
    BusyIndicatorComponent,
    EmptyStateComponent,
    ErrorDetailsComponent,
    LayoutComponent,
    MenuComponent,
    UserComponent,
    LoginComponent,
    UnauthorizedComponent,
    ListComponent,
    ListTableComponent,
    ListFilterComponent,
    ListRefreshComponent,
    ChangePasswordComponent,
    UserProfileComponent,
    BooleanSelectComponent,
    IconSelectComponent,
    ConfirmDialogComponent,
    EwsDatePipe,
    StatusChipComponent,
  ],
  exports: [
    WhenLoadingDirective,
    WhenLoadedDirective,
    WhenEmptyDirective,
    WhenNotEmptyDirective,
    WhenErrorDirective,
    BusyIndicatorComponent,
    EmptyStateComponent,
    ErrorDetailsComponent,
    JsonEditorModule,
    ListComponent,
    ListTableComponent,
    ListFilterComponent,
    ListRefreshComponent,
    UserProfileComponent,
    BooleanSelectComponent,
    IconSelectComponent,
    EwsDatePipe,
    StatusChipComponent,
  ],
  entryComponents: [
    ConfirmDialogComponent
  ],
  providers: [
    ConfirmDialogService,
    EwsDatePipe,
  ]
})
export class CommonUiModule { }
