import { Injectable } from '@angular/core';
import { CanDeactivate, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ConfirmDialogService } from '../confirm-dialog/confirm-dialog.service';
import { DirtyStateComponent } from './dirty-state.component';

@Injectable({
  providedIn: 'root',
})
export class DirtyCheckGuard implements CanDeactivate<DirtyStateComponent> {

  constructor(private confirmDialog: ConfirmDialogService) {
  }

  public canDeactivate(component: DirtyStateComponent): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return component.isDirty$.pipe(
      switchMap(x => x ? this.confirmDeactivation() : of(true))
    );
  }

  private confirmDeactivation(): Observable<boolean> {
    return this.confirmDialog.showDialog(
    {
      title: 'COMMON_UI.DIRTY_CHECK.CONFIRM.TITLE',
      question: 'COMMON_UI.DIRTY_CHECK.CONFIRM.QUESTION',
    });
  }

}