<mat-sidenav-container class="layout" [hasBackdrop]="false">
    <mat-sidenav #menu class="layout__sidenav" mode="side" opened>
        <a href="/"><div class="logo"></div></a>
        <data-portal-user></data-portal-user>
        <data-portal-menu title="Menu" [menuTree]="administrationMenu" *ngIf="isAdministrator$ | async"></data-portal-menu>
        <data-portal-menu title="Menu" [menuTree]="dashboardsMenu$ | async" [showTitle]="(isAdministrator$ | async) === false"></data-portal-menu>
    </mat-sidenav>
    <mat-sidenav-content class="layout__content">
        <mat-toolbar class="toolbar">
            <button class="toolbar__menu-button" mat-icon-button (click)="menu.toggle()"><mat-icon>menu</mat-icon></button>
            <div style=" flex: 1 1 auto"></div>
            <button class="toolbar__button" mat-button routerLink="/administration/profile">My profile</button>
            <mat-divider class="toolbar__divider" [vertical]="true"></mat-divider>
            <button class="toolbar__button" mat-button (click)="logout()">Log out</button>
        </mat-toolbar>

        <div class="content">
            <div class="content__container">
                <router-outlet></router-outlet>
            </div>
        </div>

        <div class="view-actions">
            <!-- <ng-template [cdkPortalOutlet]="activePortal$ | async"></ng-template> -->
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>