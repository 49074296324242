<div *ngIf="showTitle" class="title">
  <p>{{ title }}</p>
  <div *ngIf="subtitleTemplate" class="title__subtitle">
    <ng-template [ngTemplateOutlet]="subtitleTemplate"></ng-template>
  </div>
</div>

<mat-tree [dataSource]="menuTree" [treeControl]="treeControl" class="menu-tree">

  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle class="menu-tree__node"
    [matTooltip]="node.tooltip" matTooltipPosition="after">
    <a *ngIf="node.disabled" class="node node--disabled">
      <ng-template [ngTemplateOutlet]="nodeTemplate" [ngTemplateOutletContext]="{$implicit: node}"></ng-template>
    </a>
    <a *ngIf="node.link && !node.disabled" [routerLink]="node.link" class="node" matRipple>
      <ng-template [ngTemplateOutlet]="nodeTemplate" [ngTemplateOutletContext]="{$implicit: node}"></ng-template>
    </a>
    <a *ngIf="node.href && !node.disabled" [attr.href]="node.href" class="node" matRipple>
      <ng-template [ngTemplateOutlet]="nodeTemplate" [ngTemplateOutletContext]="{$implicit: node}"></ng-template>
    </a>

    <ng-template #nodeTemplate let-node>
      <button mat-icon-button disabled></button>
      <mat-icon class="node__icon node__icon--spaced">{{ node.icon }}</mat-icon>
      <span class="node__text">{{ node.name }}</span>
    </ng-template>
  </mat-tree-node>

  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild" [matTooltip]="node.tooltip"
    matTooltipPosition="after">
    <li class="menu-tree__node" matTreeNodeToggle>
      <div class="node" matRipple>

        <mat-icon class="node__icon node__icon--spaced">{{ node.icon }}</mat-icon>
        <span class="node__text node__text--header">{{ node.name }}</span>
        <div style="flex: 1 1 auto"></div>
        <button mat-icon-button matTreeNodeToggle>
          <mat-icon class="node__icon">
            {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
          </mat-icon>
        </button>
      </div>
      <ul [class.menu-tree__node--invisible]="!treeControl.isExpanded(node)">
        <ng-container matTreeNodeOutlet></ng-container>
      </ul>
    </li>
  </mat-nested-tree-node>

</mat-tree>