import { Component, Input, AfterViewInit } from '@angular/core';
import { ValidationErrors, FormGroup, ControlContainer, FormGroupDirective } from '@angular/forms';
import { ValidationFailure } from '../server-error.model';

export interface IErrorListEntry {
  id: string,
  details: ValidationFailure | Record<string, unknown>,
}

@Component({
  selector: 'data-portal-error-list',
  templateUrl: './error-list.component.html',
  styleUrls: ['./error-list.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ]
})
export class ErrorListComponent implements AfterViewInit {

  constructor(private formGroupDirective: FormGroupDirective) {
  }

  @Input()
  public propertyName: string = undefined;

  public form: FormGroup = undefined;

  public ngAfterViewInit(): void {
    this.form = this.formGroupDirective.form;
  }

  public get isProperty(): boolean {
    return this.propertyName !== undefined;
  }

  public get errors(): Array<IErrorListEntry> {
    return this.getErrors();
  }

  public get propertyError(): IErrorListEntry {
    const [first] = this.getErrors();

    return first;
  }

  private getErrors(): Array<IErrorListEntry> {
    if (this.form === undefined)
      return [];

    if (this.propertyName !== undefined) {
      const propertyField = this.form.controls[this.propertyName];

      return this.mapErrors(propertyField.errors);
    }

    return this.mapErrors(this.form.errors);
  }

  private mapErrors(errors: ValidationErrors): Array<IErrorListEntry> {
    return Object.keys(errors || [])
      .map(x => ({ id: x.toUpperCase(), details: this.details(errors[x]) }));
  }

  private details(errorValue: ValidationFailure | boolean | Record<string, unknown>): ValidationFailure | Record<string, unknown> {
    return errorValue && (<ValidationFailure>errorValue).propertyValueValidationDetails
      ? (<ValidationFailure>errorValue).propertyValueValidationDetails
      : typeof (errorValue) === 'boolean' ? {} : errorValue;
  }
}
