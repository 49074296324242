import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AuthenticationApiClientService } from './authentication-api-client.service';
import { AuthenticationService, sessionInitializer } from './authentication.service';
import { UnauthorizedHttpInterceptorServiceProvider } from './unauthorized-http-interceptor.service';
import { ForbiddenHttpInterceptorServiceProvider } from './forbidden-http-interceptor.service';
import { CurrentUserService } from './current-user.service';
import { ChangePasswordService } from './change-password.service';
import { AdministratorGuard } from './guards';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
  ],
})
export class IdentityModule {
  public static forRoot(): ModuleWithProviders<IdentityModule> {
    return {
      ngModule: IdentityModule,
      providers: [
        AuthenticationApiClientService,
        AuthenticationService,
        ChangePasswordService,
        CurrentUserService,
        UnauthorizedHttpInterceptorServiceProvider,
        ForbiddenHttpInterceptorServiceProvider,
        sessionInitializer,
        AdministratorGuard,
      ]
    }
  }
}
