import { Component, OnDestroy, OnInit } from '@angular/core';
import { CurrentUserService, User } from '@data-portal/identity';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'data-portal-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit, OnDestroy {

  public user$: Observable<User>;
  public onPasswordChangedReturnUrl: string;
  private destroyed$ = new Subject<void>();

  constructor(private currentUser: CurrentUserService) { }

  public ngOnInit(): void {
    this.user$ = this.currentUser.user;
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
  }

}
