import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'data-portal-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss']
})
export class EmptyStateComponent {

  @Output()
  public ctaClicked = new EventEmitter<void>();

  @Input()
  public title: string;

  @Input()
  public message: string;

  @Input()
  public readonly: false;

  @Input()
  public ctaLabel: string;

  @Input()
  public ctaLink: string;

  public sendCtaClicked(): void {
    this.ctaClicked.next();
  }

}
