import { Component, OnDestroy, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';

export interface IRefreshState {
  enabled: boolean;
  interval?: number;
}

@Component({
  selector: 'data-portal-list-refresh',
  templateUrl: './list-refresh.component.html',
  styleUrls: ['./list-refresh.component.scss']
})
export class ListRefreshComponent implements OnDestroy {

  @Output()
  public intervalChanged = new BehaviorSubject<IRefreshState>({ enabled: true, interval: 30 });

  public dataForm: FormGroup;

  private destroyed$ = new Subject<void>();

  constructor(fb: FormBuilder) {
    this.dataForm = fb.group({
      interval: [30, [Validators.required, Validators.min(30)]],
      enabled: [true],
    });

    this.dataForm.valueChanges
      .pipe(
        takeUntil(this.destroyed$),
        debounceTime(500),
        filter(_ => this.dataForm.valid),
      ).subscribe(x => {
        const control = this.dataForm.get('interval');
        if (x.enabled) {
          control.enable({ emitEvent: false });
        }
        else {
          control.disable({ emitEvent: false });
        }

        this.intervalChanged.next(this.dataForm.getRawValue());
      });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
  }

}
