<h2 mat-dialog-title>{{ settings.title | translate }}</h2>
<mat-dialog-content class="container">
  <div class="container__question" [innerHTML]="settings.question | translate:settings.questionParams"></div>
</mat-dialog-content>
<mat-dialog-actions>
  <button *ngIf="settings.showCancelButton" mat-flat-button mat-dialog-close>
    {{ 'COMMON_UI.CONFIRM_DIALOG.BUTTONS.CANCEL' | translate }}
  </button>
  <button mat-flat-button color="accent" [mat-dialog-close]="true">
    {{ (settings.confirmText || 'COMMON_UI.CONFIRM_DIALOG.BUTTONS.OK') | translate }}
  </button>
</mat-dialog-actions>
