interface IMenuItem {
  name: string;
  icon: string;
  tooltip?: string;
  disabled?: boolean;
}

export interface IMenuNode extends IMenuItem {
  tooltip?: never;
  children: Array<IMenuNode | IMenuLink>;
}

export interface IMenuLinkWithLink extends IMenuItem {
  link: string;
  href?: never;
}

export interface IMenuLinkWithHref extends IMenuItem {
  link?: never;
  href: string;
}

export type IMenuLink = IMenuLinkWithLink | IMenuLinkWithHref;

export function isMenuLink(node: IMenuNode | IMenuLink): node is IMenuLink {
  return (node as IMenuLink).link !== undefined || (node as IMenuLink).href !== undefined;
}
