import { Component, Input, OnInit } from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { ValidationError } from '@data-portal/validation';

type ErrorDetails = { error: ValidationError }

@Component({
  selector: 'data-portal-error-details',
  templateUrl: './error-details.component.html',
  styleUrls: ['./error-details.component.scss']
})
export class ErrorDetailsComponent implements OnInit {

  @Input()
  public errorDetails: ErrorDetails;

  public message: SafeHtml;

  constructor(private sanitizer: DomSanitizer) {
  }

  public reload(): void {
    window.location.reload();
  }

  public ngOnInit() {
    this.message = this.sanitizer.bypassSecurityTrustHtml(this.createMessage(this.errorDetails));
  }

  private createMessage(data: ErrorDetails): string {
    if (data?.error?.message)
      return data.error.message;

    if (data?.error?.validationFailures) {
      return data.error.validationFailures.reduce((acc, validationError) => {
        const li = document.createElement('li');
        li.innerText = validationError.message;
        acc.appendChild(li);
        return acc;
      }, document.createElement('ul')).outerHTML;
    }


    return 'Unexpected error occurred';
  }

}
