import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IMenuLinkWithLink } from '@data-portal/common-ui';
import { Observable, ReplaySubject } from 'rxjs';

export interface IDashboardMenuItem {
  id: number;
  icon: string;
  name: string;
  description: string;
}

@Injectable({ providedIn: 'root' })
export class DashboardMenuService {
  constructor(private http: HttpClient) {
    this.updateMenuItems();
  }

  public menuItems = new ReplaySubject<Array<IMenuLinkWithLink>>();

  public updateMenuItems() {
    this.getDashboardsMenuItems()
      .subscribe(items => {
        const menu = items.map(x => ({
          name: x.name,
          tooltip: x.description,
          icon: x.icon,
          link: `/dashboards/${x.id}`
        } as IMenuLinkWithLink));

        const instruments: IMenuLinkWithLink = {
          name: 'Instruments',
          icon: 'apps',
          link: '/dashboards/instruments'
        };

        this.menuItems.next([instruments, ...menu]);
      });

  }

  private getDashboardsMenuItems(): Observable<Array<IDashboardMenuItem>> {
    return this.http.get<Array<IDashboardMenuItem>>('api/v1.0/configurations/dashboards/all');
  }

}