import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export class ConfirmDialogSettings {
  title: string;
  question?: string;
  questionParams?= {};
  confirmText?: string;
  showCancelButton?: boolean = true;
}

export const defaultSettings: ConfirmDialogSettings = {
  title: 'COMMON_UI.CONFIRM_DIALOG.DEFAULT_HEADER',
  question: 'COMMON_UI.CONFIRM_DIALOG.DEFAULT_QUESTION',
}

@Component({
  selector: 'data-portal-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {

  public settings = new ConfirmDialogSettings();

  constructor(@Inject(MAT_DIALOG_DATA) data: ConfirmDialogSettings) {
    this.settings = { ...this.settings, ...data };
  }
}
