import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService, CurrentUserService } from '@data-portal/identity';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DashboardMenuService } from './dashboard-menu.service';
import { IMenuNode } from './menu/model/menu-node.model';

@Component({
  selector: 'data-portal-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent {

  constructor(private router: Router, private auth: AuthenticationService, currentUser: CurrentUserService,
    dashboardsMenu: DashboardMenuService) {
    this.isAdministrator$ = currentUser.isAdministrator;
    this.dashboardsMenu$ = dashboardsMenu.menuItems.pipe(map(items => ([
      {
        icon: 'analytics',
        name: 'Dashboards',
        children: items,
      } as IMenuNode,
    ])));
  }

  public isAdministrator$: Observable<boolean>;

  public administrationMenu: Array<IMenuNode> = [
    {
      icon: 'admin_panel_settings',
      name: 'Administration',
      children: [
        {
          icon: 'manage_accounts',
          name: 'Users',
          tooltip: 'Users management',
          link: '/administration/users',
        },
        {
          icon: 'upload_file',
          name: 'Uploads',
          tooltip: 'List of uploaded files',
          link: '/administration/uploads'
        },
        {
          icon: 'cloud_upload',
          name: 'Uploaders',
          tooltip: 'Uploaders configuration management',
          link: '/administration/uploader-configurations',
        },
        {
          icon: 'code',
          name: 'Parsers',
          tooltip: 'Parsers configuration management',
          link: '/administration/parser-configurations'
        },
        {
          icon: 'integration_instructions',
          name: 'Data structures',
          tooltip: 'Data structures configuration management',
          link: '/administration/data-structures-configurations'
        },
        {
          icon: 'analytics',
          name: 'Dashboard configurations',
          tooltip: '',
          link: '/administration/dashboard-configurations'
        },
        {
          icon: 'app_registration',
          name: 'Instrument dashboard configurations',
          tooltip: '',
          link: '/administration/instrument-dashboard-configurations'
        },
        {
          icon: 'system_update_alt',
          name: 'Uploader installers',
          tooltip: '',
          link: '/administration/installers'
        },
      ]
    }
  ];


  public dashboardsMenu$: Observable<Array<IMenuNode>>;


  public logout(): void {
    this.auth.logout().subscribe(_ => {
      this.router.navigateByUrl('/login');
    });
  }

}
