import {
  animate,
  keyframes,
  query,
  stagger,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';

export const actionButtonRotate = trigger('actionButtonRotate', [
  state('active', style({
    transform: 'rotate(135deg)'
  })),
  state('inactive', style({
    transform: 'rotate(0deg)'
  })),
  transition('*=>active', animate('0.3s ease-in-out', keyframes([
    style({ transform: 'rotate(135deg)' }),
  ]))),
  transition('*=>inactive', animate('0.3s ease-in-out', keyframes([
    style({ transform: 'rotate(0deg)' }),
  ])))
])

export const actionButtonsDisplay = trigger('actionButtonsDisplay', [
  transition('*=>*', [
    query(':enter', style({ opacity: 0 }), {optional: true}),

    query(':enter', stagger(100, animate('0.15s ease-in-out', keyframes([
      style({opacity: 0, transform: 'translateY(1rem)'}),
      style({opacity: 1, transform: 'translateY(0)'}),
    ]))), {optional: true}),

    query(':leave', stagger(100, animate('0.15s ease-in-out', keyframes([
      style({opacity: 1, transform: 'translateY(0)'}),
      style({opacity: 0, transform: 'translateY(1rem)'}),
    ]))), {optional: true})
  ])
])
