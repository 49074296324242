import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ChangePasswordService } from '@data-portal/identity';
import { ServerValidation, ServerError, CustomValidators } from '@data-portal/validation';
import { throwError, BehaviorSubject, Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'data-portal-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {

  public changePasswordForm: FormGroup;

  private validationError$ = new BehaviorSubject<ServerError>(undefined);
  private submitted$ = new BehaviorSubject<boolean>(false);
  private subscriptions = new Subscription();

  constructor(private service: ChangePasswordService, private fb: FormBuilder, private router: Router, private snackBar: MatSnackBar) { }

  public ngOnInit() {

    this.changePasswordForm = this.fb.group({
      currentPassword: [undefined, [Validators.required], [ServerValidation.propertyValidator(this.validationError$)]],
      newPassword: [undefined, [Validators.required], [ServerValidation.propertyValidator(this.validationError$)]],
      newPasswordRepeated: [undefined,
        [
          Validators.required,
          CustomValidators.controlValueMustMatch(
            () => this.changePasswordForm && this.changePasswordForm.get('newPassword'), 'REPEATED_PASSWORD_NOT_MATCH'),
        ],
      ],
    }, {
      asyncValidators: [ServerValidation.formValidator(this.validationError$, this.submitted$)],
    });

    this.subscriptions.add(
      this.changePasswordForm.get('newPassword').valueChanges
        .subscribe(_ => this.changePasswordForm.get('newPasswordRepeated').updateValueAndValidity()));

    this.subscriptions.add(
      ServerValidation.monitorFormControlsValueChange(this.changePasswordForm, this.submitted$));
    this.subscriptions.add(
      ServerValidation.monitorValidationErrors(this.validationError$, this.changePasswordForm, this.submitted$));
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public changePassword(): void {
    if (!this.changePasswordForm.valid)
      return;

    const {currentPassword, newPassword}: {currentPassword: string, newPassword: string} = this.changePasswordForm.getRawValue();

    this.subscriptions.add(
      this.service.changePassword(currentPassword, newPassword).subscribe(_ => this.onPasswordChanged(),
        error => {
          if (!ServerValidation.tryHandle(error, this.validationError$)) {
            throwError(error)
          }
        }
      ));
  }

  private onPasswordChanged(): void {
    this.snackBar.open('You have changed your password. You can now log in using your new password.', 'Close');
    this.router.navigateByUrl('/login');
  }
}
