import { HttpParams } from '@angular/common/http';

export interface IMapConfiguration {
  datesAsUtc: boolean;
}

const DefaultConfiguration: IMapConfiguration = {
  datesAsUtc: false,
}

export function mapToHttpParams(obj: { [key: string]: unknown }, config: IMapConfiguration = DefaultConfiguration): HttpParams {
  const params: { [param: string]: string } = {}
  Object.keys(obj).forEach(key => {
    const value = obj[key];

    if (value || value === false) {
      params[key] = serializeParam(value, config);
    }
  })

  return new HttpParams({ fromObject: params });
}

function serializeParam(value: unknown, config: IMapConfiguration): string {
  if (value instanceof Date) {
    return config.datesAsUtc
      ? new Date(toUtcTimestamp(value)).toISOString()
      : value.toISOString();
  }

  return <string>value;
}

function toUtcTimestamp(date: Date) {
  return Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
    date.getMilliseconds());
}
