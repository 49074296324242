import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService, LoginForm } from '@data-portal/identity';

@Component({
  selector: 'data-portal-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  public loginForm: FormGroup;

  public get isUserNameValid() {
    return this.loginForm.get('userName').valid;
  }

  public get isPasswordValid() {
    return this.loginForm.get('password').valid;
  }

  constructor(fb: FormBuilder, private auth: AuthenticationService, private route: ActivatedRoute, private router: Router) {
    this.loginForm = fb.group({
      userName: [undefined, Validators.required],
      password: [undefined, Validators.required],
      rememberMe: [false],
    });
  }

  public login(): void {
    this.loginForm.markAsTouched();

    if (this.loginForm.valid) {
      const dto = this.loginForm.getRawValue();
      const form = new LoginForm(dto.userName, dto.password, dto.rememberMe);
      this.auth.login(form).subscribe(() =>
        {
          const returnUrl = this.route.snapshot.queryParams['returnUrl'] ?? '/';
          this.router.navigateByUrl(returnUrl);
        }, () => this.loginForm.patchValue({ userName: undefined, password: undefined }));
    }
  }

}
