export interface IOptions {
  fileName: string;
  fieldSeparator: string;
  quoteStrings: string;
  decimalSeparator: string;
  showLabels: boolean;
  showTitle: boolean;
  title: string;
  useBom: boolean;
  headers: string[];
  noDownload: boolean;
  useHeader: boolean;
  nullToEmptyString: boolean;
}

export class CsvConfigConst {
  public static EOL = '\r\n';
  public static BOM = '\ufeff';

  public static DEFAULT_FIELD_SEPARATOR = ',';
  public static DEFAULT_DECIMAL_SEPARATOR = '.';
  public static DEFAULT_QUOTE = '"';
  public static DEFAULT_SHOW_TITLE = false;
  public static DEFAULT_TITLE = 'Exported data table';
  public static DEFAULT_FILENAME = 'table';
  public static DEFAULT_SHOW_LABELS = false;
  public static DEFAULT_USE_BOM = true;
  public static DEFAULT_HEADER: [] = [];
  public static DEFAULT_USE_HEADER = false;
  public static DEFAULT_NO_DOWNLOAD = false;
  public static DEFAULT_NULL_TO_EMPTY_STRING = true;
}

export const ConfigDefaults: IOptions = {
  fileName: CsvConfigConst.DEFAULT_FILENAME,
  fieldSeparator: CsvConfigConst.DEFAULT_FIELD_SEPARATOR,
  quoteStrings: CsvConfigConst.DEFAULT_QUOTE,
  decimalSeparator: CsvConfigConst.DEFAULT_DECIMAL_SEPARATOR,
  showLabels: CsvConfigConst.DEFAULT_SHOW_LABELS,
  showTitle: CsvConfigConst.DEFAULT_SHOW_TITLE,
  title: CsvConfigConst.DEFAULT_TITLE,
  useBom: CsvConfigConst.DEFAULT_USE_BOM,
  headers: CsvConfigConst.DEFAULT_HEADER,
  useHeader: CsvConfigConst.DEFAULT_USE_HEADER,
  noDownload: CsvConfigConst.DEFAULT_NO_DOWNLOAD,
  nullToEmptyString: CsvConfigConst.DEFAULT_NULL_TO_EMPTY_STRING,
};

export class CsvExporter<TModel> {
  private options: IOptions;

  private static isFloat(input: any): boolean {
    return +input === input && (!isFinite(input) || Boolean(input % 1));
  }

  constructor(private data: Array<TModel>, options?: Partial<IOptions>) {
    const config = options || {};
    this.options = Object.assign({}, ConfigDefaults, config);
  }

  public generateCsv(): string | undefined {
    let csv = '';

    if (this.options.useBom) csv += CsvConfigConst.BOM;

    if (this.options.showTitle) csv += this.options.title + '\r\n\n';

    csv += this.getHeaders();
    csv += this.getBody();

    if (this.options.noDownload) {
      return csv;
    }

    const blob = new Blob([csv], { type: 'text/csv;charset=utf8;' });
    const fileName = this.options.fileName.replace(/ /g, '_') + '.csv';

    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.setAttribute('target', '_blank');
      link.setAttribute('visibility', 'hidden');
      link.download = fileName;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  private getHeaders(): string {
    if (this.options.headers.length > 0) {
      const { headers } = this.options;
      let row = headers.reduce((headerRow, header) => {
        return headerRow + header + this.options.fieldSeparator;
      }, '');
      row = row.slice(0, -1);
      return row + CsvConfigConst.EOL;
    }

    return '';
  }

  private getBody(): string {
    let csvBody = '';
    for (let i = 0; i < this.data.length; i++) {
      let row = '';
      if (this.options.useHeader && this.options.headers.length > 0) {
        for (const index of this.options.headers) {
          row +=
            this.formatData(this.data[i][index]) + this.options.fieldSeparator;
        }
      } else {
        for (const index in this.data[i]) {
          row +=
            this.formatData(this.data[i][index]) + this.options.fieldSeparator;
        }
      }
      row = row.slice(0, -1);
      csvBody += row + CsvConfigConst.EOL;
    }

    return csvBody;
  }

  private formatData(data: any) {
    if (
      this.options.decimalSeparator === 'locale' &&
      CsvExporter.isFloat(data)
    ) {
      return data.toLocaleString();
    }

    if (this.options.decimalSeparator !== '.' && CsvExporter.isFloat(data)) {
      return data.toString().replace('.', this.options.decimalSeparator);
    }

    if (typeof data === 'string') {
      data = data.replace(/"/g, '""');
      if (
        this.options.quoteStrings ||
        data.indexOf(',') > -1 ||
        data.indexOf('\n') > -1 ||
        data.indexOf('\r') > -1
      ) {
        data = this.options.quoteStrings + data + this.options.quoteStrings;
      }
      return data;
    }

    if (this.options.nullToEmptyString) {
      if (data === null) {
        return (data = '');
      }
      return data;
    }

    if (typeof data === 'boolean') {
      return data ? 'TRUE' : 'FALSE';
    }

    return data;
  }
}
