<mat-form-field appearance="outline">
  <mat-label>{{ label }}</mat-label>

  <mat-select [(value)]="value">
    <mat-option *ngIf="anyOption">Any</mat-option>
    <mat-option [value]="true">{{ trueLabel }}</mat-option>
    <mat-option [value]="false">{{ falseLabel }}</mat-option>
  </mat-select>

</mat-form-field>
