import { Directive, Input, TemplateRef, ViewContainerRef, OnDestroy, AfterViewInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil, delay } from 'rxjs/operators';
import { IDataStatus } from '../redux-extensions/data-status.model';

@Directive({ selector: '[dataPortalWhenLoaded]' })
export class WhenLoadedDirective implements AfterViewInit, OnDestroy {
  private destroyed$ = new Subject<void>();
  private hasView = false;

  constructor(private templateRef: TemplateRef<never>, private viewContainer: ViewContainerRef) { }

  @Input()
  public dataPortalWhenLoaded: Observable<IDataStatus>;

  public ngAfterViewInit(): void {
    this.dataPortalWhenLoaded.pipe(
      delay(0), // Fix expression has changed after it was checked: https://blog.angular-university.io/angular-debugging/#howdoessettimeoutordelay0fixthisproblem
      takeUntil(this.destroyed$),
    ).subscribe(status => {
      if (status.dataLoaded && !this.hasView) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.hasView = true;
      } else if ((!status.dataLoaded) && this.hasView) {
        this.viewContainer.clear();
        this.hasView = false;
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
