import { Directive, Input, TemplateRef, ViewContainerRef, OnDestroy, AfterViewInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IDataStatus } from '../redux-extensions/data-status.model';

@Directive({ selector: '[dataPortalWhenEmpty]' })
export class WhenEmptyDirective implements AfterViewInit, OnDestroy {
  private destroyed$ = new Subject<void>();
  private hasView = false;

  constructor(private templateRef: TemplateRef<never>, private viewContainer: ViewContainerRef) { }

  @Input()
  public dataPortalWhenEmpty: Observable<IDataStatus>;

  public ngAfterViewInit(): void {
    this.dataPortalWhenEmpty.pipe(takeUntil(this.destroyed$)).subscribe(status => {
      if (status.dataLoaded && status.isEmpty && !this.hasView) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.hasView = true;
      } else if (status.dataLoaded && !status.isEmpty && this.hasView) {
        this.viewContainer.clear();
        this.hasView = false;
      } else if (!status.dataLoaded && this.hasView) {
        this.viewContainer.clear();
        this.hasView = false;
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
