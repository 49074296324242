<form [formGroup]="form">
  <mat-form-field>
    <mat-label>{{ label }}</mat-label>

    <mat-select panelClass="icon-select-panel" formControlName="icon">
      <mat-select-trigger>
        <div class="selected-value-container">
          <mat-icon *ngIf="form.controls.icon.value">{{ form.controls.icon.value }}</mat-icon>
        </div>
      </mat-select-trigger>

      <mat-option *ngFor="let icon of iconOptions" [value]="icon">
        <mat-icon [ngClass]="{'option-icon-selected': icon === form.controls.icon.value}">{{ icon }}</mat-icon>
      </mat-option>
    </mat-select>

    <mat-error>
      <data-portal-error-list propertyName="icon"></data-portal-error-list>
    </mat-error>

  </mat-form-field>
</form>