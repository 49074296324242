<div class="container">
    <section class="section section--left">
        <h1 class="box title">DataLake</h1>
    </section>
    <section class="section section--right">
        <div class="box">
            <div class="logo"></div>
            <form [formGroup]="loginForm">
              <mat-form-field>
                <mat-label>Username</mat-label>
                <input matInput formControlName="userName">
                <mat-icon matSuffix>person</mat-icon>
                <mat-error *ngIf="!isUserNameValid">Incorrect username</mat-error>
              </mat-form-field>
              <mat-form-field>
                  <mat-label>Password</mat-label>
                  <input matInput type="password" formControlName="password">
                  <mat-icon matSuffix>https</mat-icon>
                  <mat-error *ngIf="!isPasswordValid">Incorrect password</mat-error>
              </mat-form-field>
              <mat-checkbox formControlName="rememberMe">Remember me</mat-checkbox>
              <button type="submit" mat-raised-button color="primary" class="login-button" (click)="login()">Sign in</button>
            </form>
        </div>
    </section>
</div>