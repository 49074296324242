import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CustomValidators } from '@data-portal/validation';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'data-portal-icon-select',
  templateUrl: './icon-select.component.html',
  styleUrls: [ './icon-select.component.scss' ],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => IconSelectComponent),
    multi: true
  }]
})
export class IconSelectComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input()
  public label = 'Icon';
  @Input()
  public iconOptions: Array<string> = [];
  public readonly form: FormGroup;

  @Input()
  public set required(isRequired: boolean) {
    const control = this.form.get('icon');
    control.setValidators(isRequired ? [CustomValidators.requiredShort()] : []);
  }

  private destroyed$ = new Subject<void>();
  private onChange: (value: string) => void = () => ({});
  private onTouched: () => unknown = () => ({});

  constructor(fb: FormBuilder) {
    this.form = fb.group({
      icon: [undefined],
    });
  }

  public ngOnInit(): void {
    this.form.get('icon').valueChanges.pipe(takeUntil(this.destroyed$))
      .subscribe(icon => this.onChange(icon));
  }

  public writeValue(value: string): void {
    this.form.patchValue({ icon: value });
    this.onChange(value);
  }

  public setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }

  public registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => unknown): void {
    this.onTouched = fn;
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
  }

}
