import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { CurrentUserService } from '../current-user.service';

@Injectable({
  providedIn: 'root'
})
export class AdministratorGuard implements CanActivate {
  constructor(private currentUser: CurrentUserService) {
  }

  public canActivate(_: ActivatedRouteSnapshot, __: RouterStateSnapshot): Observable<boolean> {
    return this.currentUser.isAdministrator;
  }
}