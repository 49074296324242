import { Component } from '@angular/core';
import { CurrentUserService } from '@data-portal/identity';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'data-portal-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent {

  constructor(currentUser: CurrentUserService) {
    this.userName$ = currentUser.user.pipe(map(x => x.userName));
  }

  public userName$: Observable<string>;
}
