import { EMPTY, Observable, Subject } from 'rxjs';
import { catchError, debounceTime, switchMap, tap } from 'rxjs/operators';
import { IDataStatus } from '../redux-extensions/data-status.model';
import { IPage, IPageChange } from './list.model';

export function getListDataSource<TEntry>(pageChange$: Observable<IPageChange<TEntry>>,
  dataStatus$: Subject<IDataStatus>, getPage: (pageChange: IPageChange<TEntry>) => Observable<IPage<TEntry>>) {

  return pageChange$.pipe(
    debounceTime(1000),
    tap(() => dataStatus$.next({ ...IDataStatus.loadingStatus })),
    switchMap(x => getPage(x).pipe(
      catchError(error => {
        dataStatus$.next({ ...IDataStatus.errorStatus, error });
        return EMPTY;
      }),
    )),
    tap(x => dataStatus$.next(x.totalEntries > 0 ? { ...IDataStatus.loadedNonEmptyStatus } : { ...IDataStatus.loadedEmptyStatus })),
  );
}
