export type StatusType =
  'Unknown'
  | 'Disabled'
  | 'Offline'
  | 'Active'
  | 'Stale'
  | 'Error';
export const StatusType = {
  Unknown: 'Unknown' as StatusType,
  Disabled: 'Disabled' as StatusType,
  Offline: 'Offline' as StatusType,
  Active: 'Active' as StatusType,
  Stale: 'Stale' as StatusType,
  Error: 'Error' as StatusType,
}

export interface Status {
  type: StatusType;
  lastStatusTimestamp: Date | undefined;
}
