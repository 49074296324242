import { Component, Input, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { JsonEditorOptions, JsonEditorComponent as AngJsonEditorComponent } from 'ang-jsoneditor';

@Component({
  selector: 'data-portal-json-editor',
  templateUrl: './json-editor.component.html',
  styleUrls: ['./json-editor.component.scss'],
})
export class JsonEditorComponent {

  @Input()
  public set schema(value: JSON) {
    if (value)
      this.editor.setSchema(value, undefined);
  }

  @Input()
  public set editorObjectName(value: string) {
    this.options.name = value;
  }

  @Input()
  public form: FormGroup;
  @Input()
  public controlName: string;

  @Output()
  public isValid: boolean;

  @ViewChild(AngJsonEditorComponent, { static: false }) editor: AngJsonEditorComponent;
  public options = new JsonEditorOptions();

  public constructor() {
    this.options.mode = 'view';
    this.options.modes = ['view', 'code', 'tree', 'form'];
    this.options.expandAll = true;

    this.options.onValidationError = ((e: Array<unknown>) => this.isValid = e.length === 0);
    this.options.onModeChange = () => this.editor.expandAll();
  }
}
