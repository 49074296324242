export type ColumnType = 'String' | 'Date' | 'Avatar' | 'File' | 'Icon' | 'Number' | 'Boolean' | 'Template';

export const ColumnType = {
  String: 'String' as ColumnType,
  Date: 'Date' as ColumnType,
  Avatar: 'Avatar' as ColumnType,
  File: 'File' as ColumnType,
  Icon: 'Icon' as ColumnType,
  Number: 'Number' as ColumnType,
  Boolean: 'Boolean' as ColumnType,
  Template: 'Template' as ColumnType,
}

export type Column<TModel> =
  AvatarColumn<TModel> | BooleanColumn<TModel> | DateColumn<TModel> | FileColumn<TModel>
  | NumberColumn<TModel> | StringColumn<TModel> | TemplateColumn<TModel>;

class BaseColumn<TModel> {
  constructor(public readonly name: keyof TModel, public readonly header: string, public readonly type: ColumnType) {
  }
}

export class AvatarColumn<TModel> extends BaseColumn<TModel> {
  constructor(public readonly name: keyof TModel, public readonly header: string,
    public readonly isDisabled?: (element: TModel) => boolean) {
    super(name, header, ColumnType.Avatar);
  }
}

export class BooleanColumn<TModel> extends BaseColumn<TModel> {
  constructor(public readonly name: keyof TModel, public readonly header: string) {
    super(name, header, ColumnType.Boolean);
  }
}

export class DateColumn<TModel> extends BaseColumn<TModel> {
  constructor(public readonly name: keyof TModel, public readonly header: string) {
    super(name, header, ColumnType.Date);
  }
}

export class FileColumn<TModel> extends BaseColumn<TModel> {
  constructor(public readonly name: keyof TModel, public readonly header: string) {
    super(name, header, ColumnType.File);
  }
}

export class IconColumn<TModel> extends BaseColumn<TModel> {
  constructor(public readonly name: keyof TModel, public readonly header: string) {
    super(name, header, ColumnType.Icon);
  }
}

export class NumberColumn<TModel> extends BaseColumn<TModel> {
  constructor(public readonly name: keyof TModel, public readonly header: string) {
    super(name, header, ColumnType.Number);
  }
}

export class StringColumn<TModel> extends BaseColumn<TModel> {
  constructor(public readonly name: keyof TModel, public readonly header: string) {
    super(name, header, ColumnType.String);
  }
}

export class TemplateColumn<TModel> extends BaseColumn<TModel> {
  constructor(public readonly name: keyof TModel, public readonly header: string, public readonly templateName: string) {
    super(name, header, ColumnType.Template);
  }
}

export function createAvatarColumn<TModel>(name: keyof TModel, header: string, isDisabled?: (element: TModel) => boolean): Column<TModel> {
  return new AvatarColumn(name, header, isDisabled);
}
export function createBooleanColumn<TModel>(name: keyof TModel, header: string): Column<TModel> {
  return new BooleanColumn(name, header);
}
export function createDateColumn<TModel>(name: keyof TModel, header: string): Column<TModel> {
  return new DateColumn(name, header);
}
export function createFileColumn<TModel>(name: keyof TModel, header: string): Column<TModel> {
  return new FileColumn(name, header);
}
export function createIconColumn<TModel>(name: keyof TModel, header: string): Column<TModel> {
  return new IconColumn(name, header);
}
export function createNumberColumn<TModel>(name: keyof TModel, header: string): Column<TModel> {
  return new NumberColumn(name, header);
}
export function createStringColumn<TModel>(name: keyof TModel, header: string): Column<TModel> {
  return new StringColumn(name, header);
}
export function createTemplateColumn<TModel>(name: keyof TModel, header: string, templateName: string): Column<TModel> {
  return new TemplateColumn(name, header, templateName);
}
