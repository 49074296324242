import { Component, Input } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { IMenuLink, IMenuNode } from './model/menu-node.model';

@Component({
  selector: 'data-portal-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {

  @Input()
  public menuTree: Array<IMenuNode>;
  @Input()
  public showTitle = true;
  @Input()
  public title: string;
  @Input()
  public subtitleTemplate?: string;

  public showSubtitleButton = false;

  public treeControl = new NestedTreeControl<IMenuNode | IMenuLink>(
    node => (node as IMenuNode).children ? (node as IMenuNode).children : undefined);

  public hasChild(_level: number, node: IMenuNode): boolean {
    return !!node.children && node.children.length > 0;
  }

}
