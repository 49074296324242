import { AfterViewInit, Component, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'data-portal-boolean-select',
  templateUrl: './boolean-select.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => BooleanSelectComponent),
    multi: true
  }]
})
export class BooleanSelectComponent implements ControlValueAccessor, AfterViewInit {
  public value: boolean | undefined = undefined;
  @Input()
  public label: string;
  @Input()
  public trueLabel = 'Yes';
  @Input()
  public falseLabel = 'No';
  @Input()
  public anyOption = false;

  @ViewChild(MatSelect)
  private select: MatSelect

  private isDisabled = false;
  private onChange: (value: boolean) => void = () => ({});
  private onTouched: () => unknown = () => ({});

  public writeValue(value: boolean): void {
    this.value = value;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    this.select?.setDisabledState(this.isDisabled);
  }

  public registerOnChange(fn: (value: boolean) => void): void {
    this.onChange = fn;
    this.select?.registerOnChange(this.onChange);
  }

  public registerOnTouched(fn: () => unknown): void {
    this.onTouched = fn;
    this.select?.registerOnTouched(this.onTouched);
  }

  public ngAfterViewInit(): void {
    this.select.registerOnChange(this.onChange);
    this.select.registerOnTouched(this.onTouched);
    this.select.setDisabledState(this.isDisabled);
  }
}
