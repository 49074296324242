import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { SessionInfo } from './authentication-api-client.service';

export type UserRole = 'User' | 'Administrator';
export const UserRole = {
  User: 'User' as UserRole,
  Administrator: 'Administrator' as UserRole,
}

export class User {
  constructor(public readonly id: number, public readonly userName: string, public readonly roles: Array<UserRole>,
    public readonly created: Date, public readonly lastPasswordChange: Date) { }
}

@Injectable({
  providedIn: 'root'
})
export class CurrentUserService {

  private readonly _user: Subject<User> = new ReplaySubject<User>();

  public get user(): Observable<User> {
    return this._user.asObservable();
  }

  public get isAdministrator(): Observable<boolean> {
    return this.user.pipe(
      map(x => x.roles.indexOf(UserRole.Administrator) !== -1)
    );
  }

  public parseUserClaimsIdentity(session: SessionInfo): void {
    const id = parseInt(session.claims.find(x => x.type === ClaimTypes.nameIdentifier)?.value || '0');
    const userName = session.claims.find(x => x.type === ClaimTypes.userName)?.value;
    const roles = session.claims.filter(x => x.type === ClaimTypes.role).map(x => x.value as UserRole);
    const created = new Date(session.claims.find(x => x.type === ClaimTypes.created)?.value);
    const lastPasswordChange = new Date(session.claims.find(x => x.type === ClaimTypes.lastPasswordChange)?.value);
    const user = new User(id, userName, roles, created, lastPasswordChange);

    this._user.next(user);
  }

}

class ClaimTypes {
  public static readonly nameIdentifier = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier';
  public static readonly userName = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name';
  public static readonly role = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';
  public static readonly created = 'Created';
  public static readonly lastPasswordChange = 'LastPasswordChange';
}
