export interface IDataStatus {
  dataLoading: boolean;
  dataLoaded: boolean;
  isEmpty: boolean;
  error?: unknown;
}

export namespace IDataStatus {
  export const defaultStatus = {
    dataLoading: false,
    dataLoaded: false,
    isEmpty: true,
    error: undefined,
  } as IDataStatus;

  export const loadedEmptyStatus = {
    dataLoading: false,
    dataLoaded: true,
    isEmpty: true,
    error: undefined,
  } as IDataStatus;

  export const loadedNonEmptyStatus = {
    dataLoading: false,
    dataLoaded: true,
    isEmpty: false,
    error: undefined,
  } as IDataStatus;

  export const loadingStatus = {
    dataLoading: true,
    dataLoaded: false,
    isEmpty: false,
    error: undefined,
  } as IDataStatus;

  export const errorStatus = {
    dataLoading: false,
    dataLoaded: false,
    isEmpty: false,
    error: true,
  } as IDataStatus;
}