<form [formGroup]="changePasswordForm" class="container">
  <mat-form-field>
    <mat-label>Current password</mat-label>
    <input matInput input type="password" formControlName="currentPassword">
    <mat-error>
      <data-portal-error-list propertyName="currentPassword"></data-portal-error-list>
    </mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>New password</mat-label>
    <input matInput input type="password" formControlName="newPassword">
    <mat-error>
      <data-portal-error-list propertyName="newPassword"></data-portal-error-list>
    </mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>New password (again)</mat-label>
    <input matInput input type="password" formControlName="newPasswordRepeated">
    <mat-error>
      <data-portal-error-list propertyName="newPasswordRepeated"></data-portal-error-list>
    </mat-error>
  </mat-form-field>
  <mat-error>
    <data-portal-error-list></data-portal-error-list>
  </mat-error>
  <button mat-flat-button color="accent" type="submit" (click)="changePassword()" class="change-password-button" [disabled]="!changePasswordForm.valid">
    Change password
  </button>
</form>
