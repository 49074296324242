<mat-card class="container">
  <mat-card-content>
    <mat-card-header>
      <mat-card-title>My profile</mat-card-title>
      <mat-card-subtitle>On this page you can modify your profile</mat-card-subtitle>
    </mat-card-header>
    <dl class="fields content" *ngIf="user$ | async as user">
      <dt>Username</dt>
      <dd>{{ user.userName }}</dd>

      <dt>Roles</dt>
      <dd><span *ngFor="let role of user.roles">{{ role }}</span></dd>

      <dt>Created</dt>
      <dd>{{ user.created | ewsDate }}</dd>

      <dt>Last password change</dt>
      <dd>{{ user.lastPasswordChange | ewsDate }}</dd>
    </dl>
  </mat-card-content>

  <mat-card-content>
    <mat-card-header>
      <mat-card-title>Password change</mat-card-title>
      <mat-card-subtitle>Use this form to change your password</mat-card-subtitle>
    </mat-card-header>
    <div class="content change-password">
      <data-portal-change-password></data-portal-change-password>
    </div>
  </mat-card-content>
</mat-card>
