import { Component, Input } from '@angular/core';
import { StatusType, Status } from './status.model';

@Component({
  selector: 'data-portal-status-chip',
  templateUrl: './status-chip.component.html',
  styleUrls: ['./status-chip.component.scss']
})
export class StatusChipComponent {

  public readonly StatusType = StatusType;

  @Input()
  public status: Status;

}
