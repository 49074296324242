<div class="overlay">
  <mat-icon class="error-icon">sentiment_dissatisfied</mat-icon>
  <span class="mat-subheading-2">{{ 'COMMON_UI.ERROR_DETAILS.AN_ERROR_OCCURRED' | translate }}</span>
  <span class="message" [innerHTML]="message"></span>
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'COMMON_UI.ERROR_DETAILS.ERROR_DETAILS' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
        <pre class="error-details">
            <code>
{{ errorDetails | json }}
            </code>
        </pre>
    </mat-expansion-panel>
  </mat-accordion>
  <button class="reload-button" mat-button color="accent" (click)="reload()">
    {{ 'COMMON_UI.ERROR_DETAILS.RELOAD_APPLICATION' | translate }}
  </button>
</div>
