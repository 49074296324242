<form [formGroup]="dataForm" class="container">
  <div class="refresh">
    <mat-checkbox #enabledRefresh formControlName="enabled">Auto refresh</mat-checkbox>
    <mat-form-field appearance="outline" class="refresh__input">
      <mat-label>Interval [s]</mat-label>
      <input #intervalInput matInput type="number" formControlName="interval" [disabled]="!enabledRefresh.value" />
    </mat-form-field>
  </div>
  <mat-error *ngIf="dataForm.invalid">
    <data-portal-error-list propertyName="interval"></data-portal-error-list>
  </mat-error>
</form>