import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { isString } from 'util';

export class CustomValidators {
  public static controlValueMustMatch(getControl: () => AbstractControl, translateKey: string) {
    return (repeatedControl: AbstractControl) => {
      const control = getControl();

      if (control && control.value !== repeatedControl.value) {
        return { [translateKey]: true }
      } else {
        return null;
      }
    }
  }

  public static phoneNumber() {
    return Validators.pattern(/^(\(?\+?[0-9]*\)?)?[0-9_\- ()]*$/);
  }

  public static autocompleteObjectSelected(): ValidatorFn {
    return (control: AbstractControl) => {
      if (isString(control.value)) {

        return { ['AUTOCOMPLETE_INVALID_OPTION_SELECTED']: true }
      } else {
        return null;
      }
    }
  }

  public static decimal(maxScale = 0): ValidatorFn {
    // Number type is 64-bit floating point (IEEE 754), max 9_007_199_254_740_991
    // To manage the entire range of numbers from 1 to the power of 10 we use 15 digits to represent the number
    // We don't handle scale greater than 4 (back-end configuration)
    const maxDigitsCount = 15;
    const maxDecimalsCount = 4;
    const maxValue = Math.pow(10, maxDigitsCount - maxDecimalsCount);

    if (maxScale > maxDecimalsCount)
      throw (`Maximum handled scale is ${maxDecimalsCount}`);

    return (control: AbstractControl) => {
      if (!control.value)
        return null;

      const match = control.value.toString().match(/^\d+\.*(?<decimals>\d*)$/);
      const maxScaleExceeded = match.groups['decimals'].length > maxScale;

      if (control.value > maxValue) {
        return { ['DECIMAL_VALIDATOR.TOO_BIG_NUMBER']: true }
      } else if (maxScaleExceeded) {
        return { ['DECIMAL_VALIDATOR.MAX_SCALE_EXCEEDED']: { maxScale } }
      }

      return null;
    }
  }

  public static requiredShort(): ValidatorFn {
    return (control: AbstractControl) => {
      if (control.value === undefined || control.value === null) {
        return { ['REQUIRED_SHORT']: true }
      } else {
        return null;
      }
    }
  }
}
